<template>
    <a-card title="" :bordered="false">
        <a-form-model class="orders-search" layout="inline">
            <a-form-model-item>
                <a-select v-model="filterFields.catid" style="width: 160px">
                    <a-select-option
                        :value="item.catid"
                        v-for="item in categroy"
                        :key="item.catid"
                        >{{ item.title }}</a-select-option
                    >
                </a-select>
            </a-form-model-item>
            <a-form-model-item>
                <a-input
                    v-model="filterFields.keyword"
                    placeholder="请输入关键词"
                    style="width: 240px"
                />
            </a-form-model-item>
            <a-form-model-item>
                <a-button
                    type="primary"
                    :loading="filterLoading"
                    @click="filterSearch"
                    >筛选</a-button
                >
            </a-form-model-item>
        </a-form-model>
        <p class="table-tips">
            接稿后，务必再任务时间内完成，否则将会影响信誉分，罚款，甚至是封账号!!!
        </p>
        <a-table
            :columns="tableColumns"
            :data-source="currentList"
            size="middle"
            :pagination="false"
            :loading="tableLoading"
            rowKey="id"
            :row-selection="rowSelection"
            :scroll="{ y: tableHeight }"
        >
            <div slot="catTitle" slot-scope="title">
                {{title}}
            </div>
            <div style="font-size: 1.2em;color: #C62333" slot="reward" slot-scope="reward">
                {{reward}}
            </div>
            <div slot="deadlineTime" slot-scope="time">
                {{formatTime(time, 'Y/M/D')}}
            </div>
            <div slot="action" slot-scope="a, record">
                <a-button type="primary" size="small" v-if="record.status == 30 || record.status == 70" :loading="acceptLoading.indexOf(record.id +'') >= 0" @click="martAccept(record.id)">接稿</a-button>
                <a-button type="primary" size="small" v-if="record.status == 80" disabled>已过期</a-button>
                <a-tooltip v-if="record.status == 777" :title="record.error777">
                    <a-button type="primary" size="small" disabled>接稿失败<a-icon type="question-circle" /></a-button>
                </a-tooltip>
                <a-button type="primary" size="small" v-if="record.status == 20" disabled>已接稿</a-button>
            </div>
        </a-table>
        <div class="table-bottom">
            <a-button type="primary" :disabled="tableSelectIds.length <= 0" @click="batchAccept">批量接稿({{tableSelectIds.length}})</a-button>
            <a-pagination
                v-if="pagination.total > pagination.limit"
                class="mark-pages"
                v-model="pagination.current"
                :total="pagination.total"
                :default-page-size="pagination.limit"
                show-less-items
                @change="pageChange" />
        </div>
    </a-card>
</template>

<script>
const tableColumns = [
    {
        title: "标题",
        dataIndex: "title",
    },
    {
        title: "类别",
        dataIndex: "cat_title",
        width: 80,
        align: 'center',
        scopedSlots: { customRender: 'catTitle' }
    },
    {
        title: "要求简述",
        dataIndex: "conditions",
    },
    {
        title: "奖励(元)",
        dataIndex: "reward",
        width: 70,
        align: 'center',
        scopedSlots: { customRender: 'reward' }
    },
    {
        title: "截止时间",
        dataIndex: "deadline_time",
        width: 120,
        align: 'center',
        scopedSlots: { customRender: 'deadlineTime' }
        
    },
    {
        title: "操作",
        dataIndex: "action",
        width: 120,
        align: 'center',
        scopedSlots: { customRender: "action" },
    },
];
const tableHeight = window.innerHeight - (72 + 30 + 250);
import { formatTime } from '@/common/date';
export default {
    data() {
        return {
            categroy: [], // 用户选择的类别
            martList: {}, // 稿件列表集合
            filterFields: {
                catid: '',
                keyword: ''
            },
            filterLoading: false,
            tableHeight,
            tableData: [],
            tableColumns,
            tableSelectKeys: [],
            tableSelectIds: [],
            tableLoading: true,
            // 接稿loading状态
            acceptLoading: [],
            // 分页配置
            pagination: {
                current: 1,
                limit: 20,
                total: 0
            }
        };
    },
    computed: {
        rowSelection() {
            return {
                selectedRowKeys: this.tableSelectKeys,
                hideDefaultSelections: true,
                // 记录稿件ID
                onChange: (selectedRowKeys, selectedRows) => {
                    this.tableSelectKeys = selectedRowKeys;
                    this.tableSelectIds = selectedRows.map(item => item.id);
                },
                // 是否可以勾选
                getCheckboxProps: record => ({
                    props: {
                        disabled: record.status != 30
                    }
                })
            }
        },
        // 当前分页列表
        currentList() {
            let thisPage = this.pagination.current;
            let thisData = this.martList['page_' + thisPage];
            return thisData ? thisData : [];
        },
    },
    created() {
        // 获取用户类别分类
		this.$store.dispatch("user/getCategory").then(res => {
            let cateArr = [{
                catid: '',
                title: '全部'
            }]
            this.categroy = cateArr.concat(res);
        });
        this.getMartList();
    },
    mounted() {
        window.onresize = () => {
            this.tableHeight = window.innerHeight - (72 + 30 + 250)
        };
    },
    destroyed() {
		// 取消退出监听事件
		window.onresize = null;
	},
    methods: {
        // 格式化时间
        formatTime(timestamp, format) {
            return formatTime(timestamp * 1000, format);
        },
        // 获取投稿中心标题
        getMartList() {
            const page = this.pagination.current;
            this.$api.manuscriptsCenter({
                keyword: this.filterFields.keyword,
                catid: this.filterFields.catid,
                page,
                limit: this.pagination.limit,
            }).then(res => {
                if (res.status) {
                    let listData = {};
                    listData['page_' + page] = res.data.list;
                    this.martList = Object.assign({}, this.martList, listData);
                    this.pagination.total = res.data.count;
                }
            }).finally(() => {
                this.filterLoading = false;
                this.tableLoading = false;
            })
        },
        // 筛选更新数据
        filterSearch() {
            this.tableLoading = true;
            this.filterLoading = true;
            this.martList = {};
            this.tableSelectKeys = [];
            this.tableSelectIds = [];
            this.pagination.current = 1;
            this.getMartList();
        },
        // 翻页
        pageChange(current) {
            this.tableSelectKeys = [];
            this.tableSelectIds = [];
            if (!this.martList['page_' + current]) {
                this.tableLoading = true;
                this.getMartList();
            }
        },
        // 批量接稿
        batchAccept() {
            this.martAccept(this.tableSelectIds.join(','));
        },
        // 接稿单个
        martAccept(ids) {
            // 多个还单个
            ids = ''+ ids;
            let idsArr = ids.indexOf(',') ? ids.split(',') : [ids];
            this.acceptLoading = idsArr;
            this.$api.manuscriptsJiegao({ ids }).then(res => {
                // res = {status: 1};
                if(res.status) {
                    if(res.data.total == res.data.success) {
                        this.$message.success({ content: '接稿成功', key: 'messageKey'})
                    }else if(res.data.success > 0){
                        this.$message.warn({ content: '部分接稿异常', key: 'messageKey'})
                    }else{
                        this.$message.error({ content: '接稿异常', key: 'messageKey'})
                    }
                    // 更新状态
                    let listData = this.martList['page_' + this.pagination.current];
                    let changeData = {};
                    let resError = res.data.error instanceof Object ? res.data.error : {};
                    for(let i = 0, max = listData.length; i < max; i++) {
                        if(idsArr.indexOf(listData[i].id+'') >= 0) {
                            // 是否有接稿错误信息
                            if(resError[listData[i].id]) {
                                changeData['a'+ i] = Object.assign({}, listData[i], {status: 777, error777: resError[listData[i].id]});
                            }else{
                                changeData['a'+ i] = Object.assign({}, listData[i], {status: 20});
                            }
                        }
                    }
                    for(let k in changeData) {
                        this.martList['page_' + this.pagination.current].splice(k.replace('a', ''), 1, changeData[k])
                    }
                    this.tableSelectKeys = [];
                    this.tableSelectIds = [];
                }else{
                    this.$message.warn({ content: res.msg, key: 'messageKey'})
                }
            }).finally(() => {
                this.acceptLoading = [];
            })
        }
    },
};
</script>

<style lang="less" scoped>
.table-tips {
    margin: 20px 0 10px;
}
.table-bottom{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}
</style>
