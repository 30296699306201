/**
 * 关于时间的处理函数
 */

/**
 * 格式化时间戳
 * @param timestamp 时间戳(毫秒)、日期格式字符串
 * @param format 格式形式Y-M-D H:I:S
 * @return string 格式化后的字符串
 */
export function formatTime(timestamp, format) {
	let date = new Date(timestamp);
	if(timestamp==='' || !date || date=='Invalid Date') {
		console.warn('格式化时间戳：参数错误', '时间戳参数：', timestamp)
		return ''
	}
	// 默认格式
	format = (format && format.length>0) ? format : 'Y-M-D H:I:S'
	const Y = date.getFullYear()//年
	const m = date.getMonth() + 1//月
	const d = date.getDate()//日
	const h = date.getHours()//时
	const i = date.getMinutes()//分
	const s = date.getSeconds()//秒
	const w = date.getDay()//周(0-6)
	const weekCnArr = ['天', '一', '二', '三', '四', '五', '六']
	const json = {
		Y: Y,//4位年份
		m: m,//月份没有前导零
		d: d,//日期没有前导零
		h: h,//时辰没有前导零
		i: i,//分钟没有前导零
		s: s,//秒没有前导零
		w: w,//周
		W: weekCnArr[w],//周(中文)
		y: Y.toString().substr(2, 2),//2位年份
		M: m < 10 ? ('0' + m) : m,//月份有前导零
		D: d < 10 ? ('0' + d) : d,//日期有前导零
		H: h < 10 ? ('0' + h) : h,//时辰有前导零
		I: i < 10 ? ('0' + i) : i,//分钟有前导零
		S: s < 10 ? ('0' + s) : s//秒没有前导零
	}
	// 整合字符串
	let resultStr = format
	for (let x in json) {
		let reg = new RegExp(x, 'g')
		resultStr = resultStr.replace(reg,json[x])
	}
	return resultStr
}

/**
 * 判断当前时间戳是否为今天
 * @param {*} timestamp 时间戳(毫秒)
 * @return boolean
 */
export function isToday(timestamp) {
	return new Date(timestamp).toLocaleDateString() === new Date().toLocaleDateString()
}

/**
 * 判断当前日期是早于、等于、晚于当前时间
 * @param {*} timestamp 时间戳(毫秒)
 * @return Number 1晚于、0等于、-1小于
 */
export function beforeOrAfter(timestamp){
	const nowStr = new Date().toLocaleDateString();
	const affStr = new Date(timestamp).toLocaleDateString()
	if(nowStr === affStr){
		return 0;
	}
	if(new Date(nowStr).getTime() < new Date(affStr).getTime()){
		return 1;
	}else{
		return -1;
	}
}

/**
 * 判断当前时间是否为7天内
 * @param {*} timestamp 时间戳(毫秒)
 * @retuen boolean
 */
export function isBefore7days(timestamp) {
	return (new Date().getTime() - new Date(timestamp).getTime()) <= (7*24*60*60*1000)
}

/**
 * 判断当前时间在多久前
 * 大于当前时间：刚刚
 * <1分钟：刚刚
 * <1小时：n分钟前
 * <24小时：n小时前
 * >24小时：n天前
 * @param {*} timestamp 时间戳(毫秒)
 */
export function timeAgo(timestamp) {
	if(timestamp < 1) return
	const thisTimestamp = parseInt(new Date().getTime()/1000)
	const diffVal = thisTimestamp - timestamp
	if(diffVal < 60) {
		return '刚刚'
	}else if(diffVal < (60*60)) {
		return parseInt(diffVal/60) + '分钟前'
	}else if(diffVal < (60*60*24)) {
		return parseInt(diffVal/(60*60)) + '小时前'
	}else{
		return parseInt(diffVal/(60*60*24)) + '天前'
	}
}

